"use client";

import React from "react";
import MenuWidget from "../Widget/MenuWidget";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import TextWidget from "../Widget/TextWidget";
import SocialWidget from "../Widget/SocialWidget";
import NewsletterStyle4 from "../Widget/NewsletterStyle4";
import { useLocale } from "next-intl";
import { usePathname } from "next/navigation";
import { useTranslations, useMessages } from "next-intl";
import { Grid } from "@mui/material";
import { usersData } from "../../../lib/users-data";

export default function FooterStyle2() {
  const t = useTranslations("footer");
  const messages = useMessages();
  const currentLang = useLocale();
  const noFramePages = ["opt-in", ...usersData.map((user) => user.slug)];
  const route = usePathname().substring(
    usePathname().lastIndexOf("/") + 1
  );

  const fromJson = (keys, basePath, keyFields) => {
    const newArr = keys.map((key) => {
      const item = {};
      keyFields.forEach((field) => {
        item[field] = t(
          `${basePath}.${key}.${field}`,
          route === "es" || "en"
            ? {
                currentLang: currentLang,
              }
            : null
        );
      });
      return item;
    });
    return newArr;
  };
  const menuDataOneLinks = [
    `/${currentLang}/opt-in`,
    `/${currentLang}/#testimonials`,
    `/${currentLang}/blogs`,
    `/${currentLang}/services`,
  ];
  const menuDataTwoLinks = [
    `/${currentLang}/about`,
    `/${currentLang}/contact`,
    `/${currentLang}/privacy-policy`,
    `/${currentLang}/terms-conditions`,
  ];

  if (noFramePages.includes(route)) {
    return null;
  } else {
    return (
      <footer className="cs-footer cs-style-2 cs-primary-bg cs-white-color cs-mt-40">
        <NewsletterStyle4
          title={t("newsletter.title")}
          subTitle={t("newsletter.subTitle")}
        />
        <div className="container wide">
          <div className="footer-grid-container">
            <div className="footer-grid-item lg-5 md-6 sm-12 xs-12 cs-footer-item">
                <TextWidget
                  logoUrl="/images/hlogo2.webp"
                />
                <ContactInfoWidget />
            </div>

            <div className="footer-grid-item lg-3 md-6 sm-12 xs-12 cs-footer-item">
                <p className="footer-heading">{t("workingHoursTitle")}</p>
                <ul className="cs-menu-widget cs-mp0">
                  {fromJson(
                    Object.keys(messages.footer.workingHours),
                    "workingHours",
                    ["day"]
                  ).map((item, index) => (
                    <li
                      key={index}
                      className="working-hours-item">
                      <p className="working-hours-day">{item.day}</p>
                      <p
                        className={`working-hours-time ${item.time === "Closed" ? "closed" : ""}`}>
                        &nbsp;
                        {index === 2
                          ? "11:00am - 7:00pm "
                          : index === 5
                            ? currentLang === "en"
                              ? "By Appointment"
                              : "Solo con cita previa"
                            : index === 6
                              ? currentLang === "en"
                                ? "Closed"
                                : "Cerrado"
                              : "8:00am - 5:00pm"}
                      </p>
                    </li>
                  ))}
                </ul>
            </div>

            <div className="footer-grid-item lg-2 md-6 sm-6 xs-12 order-lg-3 order-md-4 order-sm-4 cs-footer-item">
                <MenuWidget
                  data={fromJson(
                    Object.keys(messages.footer.menuDataOne),
                    "menuDataOne",
                    ["title"]
                  )}
                  links={menuDataOneLinks}
                />
            </div>

            <div className="footer-grid-item lg-2 md-6 sm-6 xs-12 order-lg-4 order-md-3 order-sm-3 cs-footer-item">
                <MenuWidget
                  data={fromJson(
                    Object.keys(messages.footer.menuDataTwo),
                    "menuDataTwo",
                    ["title"]
                  )}
                  links={menuDataTwoLinks}
                />
                <SocialWidget />
            </div>
          </div>
        </div>
          <div className="cs-copyright container cs-footer-bottom">
            {t("copyright")}
          </div>
      </footer>
    );
  }
}
