export const usersData = [
    {
        slug: "dr-hany-farag",
        title: "",
        description: "",
        keywords: "",
        imageUrl: "https://fanousclinicmain.blob.core.windows.net/website-images-icons/dr-hany-farag-primary-care-physician.webp?sp=r&st=2024-09-01T11:28:11Z&se=2030-01-01T20:28:11Z&spr=https&sv=2022-11-02&sr=b&sig=TzB8nhYdMPJklM%2BMAEc0Lq2MQrEgIooTSrynJaBVzm0%3D",
        imageAlt: "Dr. Hany Farag, MD MBA - Primary Care Physician at Fanous Clinic in Charlotte, NC",
        name: "Hany Farag, MD MBA",
        pos: "Primary Care Physician",
        subPos: "Dr. Hany Farag is a skilled Primary Care Physician with expertise in preventive care and patient-centered medical services. He combines his medical knowledge with business acumen to deliver comprehensive and personalized healthcare.",
        phone: "(216) 659-5992",
        email: "hany.farag.md@fanousclinic.com",
    },
]