"use client";

import { Icon } from "@iconify/react";
import React, { useState, useEffect } from "react";
import Link from "next/link";
import FooterData from "../Footer/FooterData";
import { useTranslations } from "next-intl";

export default function ContactInfoWidget() {
  const AL = useTranslations("ariaLabels");

  const [isNarrow, setIsNarrow] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsNarrow(width <= 345);
    };

    handleResize(); // Set the initial state on component mount
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const address = isNarrow
    ? "10926 South Tryon Street,\nSuite D,\nCharlotte, NC 28273"
    : "10926 South Tryon Street, Suite D,\nCharlotte, NC 28273";

  return (
    <ul className="cs-contact-widget">
      <ContactItem
        href={FooterData.Address}
        icon="ep:location"
        label={AL("links.address")}
        text={address}
      />
      <ContactItem
        href={`tel:${FooterData.Phone}`}
        icon="fluent:call-24-regular"
        label={AL("links.phone")}
        text="(980) 224-7288"
      />
      <ContactItem
        href={`mailto:${FooterData.Mail}`}
        icon="bi:envelope"
        label={AL("links.email")}
        text="info@fanousclinic.com"
      />
    </ul>
  );
}

function ContactItem({ href, icon, label, text }) {
  return (
    <li>
      <Link href={href} target="_blank" aria-label={label} className="contact-item">
        <Icon icon={icon} className="cs-icon" />
        {text}
      </Link>
    </li>
  );
}
