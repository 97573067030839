"use client";
import { Box, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Link from "next/link";
import Button from "../Button";
import { usePathname } from "next/navigation";
import { useLocale } from "next-intl";
import { useTranslations } from "next-intl";
import LangToggle from "../lang-switch/LangToggle.tsx";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Popup from "../Popup/Popup";
import BookingForm from "../forms/BookingForm/index";
import { usersData } from "../../../lib/users-data";
import CustomImage from "../CustomImage/CustomImage";
import Image from "next/image";

export default function Header({ logoSrc, altLogoSrc, variant }) {
  const currentLang = useLocale();
  const pathname = usePathname();
  const route = pathname.substring(pathname.lastIndexOf("/") + 1);
  const t = useTranslations("nav");

  const [logo, setLogo] = useState(logoSrc);
  const [isSticky, setIsSticky] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const [open, setOpen] = useState(false);
  const [bookingFormOpened, setBookingFormOpened] = useState(false);

  const noFramePages = useMemo(
    () => ["opt-in", ...usersData.map((user) => user.slug)],
    []
  );

  const noAutoPopUp = useMemo(
    () => ["contact", ...usersData.map((user) => user.slug)],
    []
  );

  // Handle scroll for sticky header and form pop-up
  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY;
    const pageHeight = document.documentElement.scrollHeight;
    const scrollThreshold = 50;

    if (scrollPosition > scrollThreshold) {
      setShowBox(true);
      setIsSticky(true);
      setLogo(altLogoSrc ?? logoSrc);
    } else {
      setShowBox(false);
      setIsSticky(false);
      setLogo(logoSrc);
    }

    if (
      scrollPosition >= pageHeight / 2 &&
      !bookingFormOpened &&
      !noAutoPopUp.includes(route)
    ) {
      setOpen(true);
      setBookingFormOpened(true);
    }
  }, [altLogoSrc, bookingFormOpened, logoSrc, noAutoPopUp, route]);

  // Handle screen resize for mobile view
  const handleResize = useCallback(() => {
    setMobileView(window.matchMedia("(max-width: 1199px)").matches);
  }, []);

  // Consolidated useEffect for scroll and resize listeners
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    handleResize(); // Initial check for mobile view

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleScroll, handleResize]);

  // Handle auto-popup after timeout
  useEffect(() => {
    if (!noAutoPopUp.includes(route) && !bookingFormOpened) {
      const timer = setTimeout(() => {
        setOpen(true);
        setBookingFormOpened(true);
      }, 15000);

      return () => clearTimeout(timer); // Cleanup on unmount
    }
  }, [bookingFormOpened, noAutoPopUp, route]);

  // Close form if route changes to "contact"
  useEffect(() => {
    if (route === "contact") {
      setBookingFormOpened(false);
    }
  }, [route]);

  // Reset mobile toggle on route change
  useEffect(() => {
    setMobileToggle(false);
  }, [pathname]);

  // Early return for no-frame pages
  if (noFramePages.includes(route)) return null;
  return (
    <>
      <header
        className={`cs-site-header cs-style1 cs-sticky-header ${variant} ${
          isSticky ? "cs-active-sticky" : ""
        }`}>
        <div className="container wide">
          <div className="cs-main-header-in">
            <Link
              className="cs-site-branding"
              href={`/${currentLang}`}>
              <CustomImage
                notResponsive
                className="fc-logo"
                src={logo}
                alt="Logo"
                height={28}
                width={173}
              />
            </Link>
            <nav className="cs-nav">
              <ul
                className={`${
                  mobileToggle ? "cs-nav-list cs-active" : "cs-nav-list"
                }`}>
                <li
                  className="nav-items"
                  onClick={
                    mobileToggle ? () => setMobileToggle(false) : null
                  }>
                  <ul
                    className={`no-style ${mobileView ? "column" : "row"} ${!isSticky && !mobileToggle ? "non-sticky" : "sticky"}`}>
                    <li>
                      <Link href={`/${currentLang}`}>{t("home")}</Link>
                    </li>
                    <li>
                      <Link href={`/${currentLang}/services`}>
                        {t("services")}
                      </Link>
                    </li>
                    <li>
                      <Link href={`/${currentLang}/blogs`}>
                        {t("blogs")}
                      </Link>
                    </li>
                    <li>
                      <Link href={`/${currentLang}/about`}>
                        {t("about")}
                      </Link>
                    </li>
                    <li>
                      <Link href={`/${currentLang}/contact`}>
                        {t("contact")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li
                  className={`list-item ${mobileView ? "column" : "row"}`}>
                  <LangToggle
                    className={`lang-toggle ${mobileView ? "mobile" : ""} ${!isSticky && !mobileToggle ? "" : "sticky"}`}
                  />
                  <Button
                    btnText={t("PP")}
                    btnUrl="https://pp-wfe-102.advancedmd.com/156528/account/logon"
                    className="button-style"
                  />
                </li>
              </ul>
              <div className="cs-toolbox">
                <button
                  className="cs-toolbox-btn cs-sidebar-toggle-btn"
                  type="button"
                  onClick={() => setMobileToggle(!mobileToggle)}
                  aria-label="menu button">
                  <svg
                    width={35}
                    height={30}
                    viewBox="0 0 35 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.483887 2.46544C0.483887 1.10383 1.14618 0 1.96315 0H33.5208C34.3377 0 35 1.10383 35 2.46544C35 3.82708 34.3377 4.93088 33.5208 4.93088H1.96315C1.14618 4.93088 0.483887 3.82708 0.483887 2.46544Z"
                      fill="currentColor"
                    />
                    <path
                      d="M0.483887 14.6694C0.483887 13.3074 1.14618 12.2039 1.96315 12.2039H33.5208C34.3377 12.2039 35 13.3074 35 14.6694C35 16.0309 34.3377 17.1348 33.5208 17.1348H1.96315C1.14618 17.1348 0.483887 16.0309 0.483887 14.6694Z"
                      fill="currentColor"
                    />
                    <path
                      d="M0.483887 26.6267C0.483887 25.2648 1.14618 24.1613 1.96315 24.1613H33.5208C34.3377 24.1613 35 25.2648 35 26.6267C35 27.9883 34.3377 29.0922 33.5208 29.0922H1.96315C1.14618 29.0922 0.483887 27.9883 0.483887 26.6267Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
            </nav>
          </div>
        </div>
        <Box
          className={`header-book-appointment ${showBox ? "header-book-appointment-visible" : "header-book-appointment-hidden"}`}>
          <button
            onClick={() => {
              setOpen(true);
              setBookingFormOpened(true);
            }}
            className="button">
            <Image
              loading="lazy"
              src="/images/icons/calendar.svg"
              priority={false}
              width={24}
              height={24}
              className="calendar-icon"
              title="book apointement"
              alt="book apointement icon"
            />
            <p
              className="m-0"
             >
              {t("schedule")}
            </p>
          </button>
        </Box>
      </header>
      <Popup
        open={open}
        setOpen={setOpen}>
        <BookingForm />
      </Popup>
    </>
  );
}
